import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'
import { isUndefined } from 'lodash'

export interface IParams {
  ssrLocale?: any
  params: any
}

class FetchPopularProductsApi extends Service {
  constructor({ ssrLocale, params }: IParams) {
    super()
    this.name = 'FETCH_POPULAR_PRODUCTS'

    const basicConfig = {
      url: `/v1/product/event`,
      method: 'GET',
      params: { ...params },
    }

    const localeConfig = {
      'Tutor-Header-Locale': ssrLocale,
    }

    const config = isUndefined(ssrLocale) ? basicConfig : { ...basicConfig, headers: localeConfig }

    this.config = config
  }
}

export const fetchPopularProducts = (params: IParams) => getCallApiFunction(new FetchPopularProductsApi(params))
