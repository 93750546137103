import { BigNumber } from 'bignumber.js'

interface IParams {
  isLive?: boolean
  origPrice?: number
  minPrice: number
  livePrice: number
}

export const bigNumberFormater = (inputNumber: number) => {
  if (isNaN(inputNumber)) {
    return ''
  }
  return new BigNumber(inputNumber).toFormat()
}

const getDisplayPrice = ({ isLive = true, origPrice = 0, minPrice, livePrice }: IParams) => {
  const displayOrigPrice = bigNumberFormater(origPrice)

  // displayPrice 含千分位的字串
  // productPrice 單純金額的數字
  if (isLive && livePrice > 0) {
    return {
      priceLabel: 'Common_Live_Price',
      displayPrice: bigNumberFormater(livePrice),
      displayOrigPrice,
      productPrice: livePrice,
      productOriginalPrice: origPrice,
    }
  } else {
    return {
      priceLabel: 'Common_Discount_Price',
      displayPrice: bigNumberFormater(minPrice),
      displayOrigPrice,
      productPrice: minPrice,
      productOriginalPrice: origPrice,
    }
  }
}

interface Props {
  filteredItems?: any
  isLive?: boolean
  productInfo?: any
  amount?: number
}

const displayPriceAdapter = (priceList: Array<number>) => {
  if (priceList?.length > 1) {
    const bigNumberMinPrice = bigNumberFormater(priceList[0])
    const bigNumberMaxPrice = bigNumberFormater(priceList[1])
    return bigNumberMinPrice && bigNumberMaxPrice ? `$${bigNumberMinPrice}-$${bigNumberMaxPrice}` : ''
  }
  const bigNumberPrice = bigNumberFormater(priceList[0])
  return bigNumberPrice ? `$${bigNumberPrice}` : ''
}

export const getDisplayPriceWithRange = ({
  filteredItems,
  isLive,
  productInfo,
  amount = 1,
}: Props): { displayPrice: string; displayLabel: string; displayOrigPrice?: string; origPrice?: string } => {
  const result = {
    displayPrice: '',
    displayLabel: '',
    displayOrigPrice: '',
  }
  const calcAmount = amount > 0 ? amount : 1

  const minOrigPrice = productInfo?.minOrigPrice
  const maxOrigPrice = productInfo?.maxOrigPrice
  minOrigPrice === maxOrigPrice
    ? (result.displayOrigPrice = displayPriceAdapter([minOrigPrice * calcAmount]))
    : (result.displayOrigPrice = displayPriceAdapter([minOrigPrice * calcAmount, maxOrigPrice]))

  if (filteredItems.length === 1) {
    if (isLive) {
      result.displayPrice =
        filteredItems[0].livePrice > 0
          ? displayPriceAdapter([filteredItems[0].livePrice * calcAmount])
          : displayPriceAdapter([filteredItems[0].salePrice * calcAmount])
      result.displayLabel = filteredItems[0].livePrice > 0 ? `Common_Live_Price` : `Common_Discount_Price`
    } else {
      result.displayPrice = displayPriceAdapter([filteredItems[0].salePrice * calcAmount])
      result.displayLabel = `Common_Discount_Price`
    }
    result.displayOrigPrice = displayPriceAdapter([filteredItems[0].origPrice * calcAmount])
  } else {
    const { minLivePrice, maxLivePrice, minPrice, maxPrice } = productInfo

    if (isLive) {
      const min = minLivePrice !== 0 ? minLivePrice : minPrice
      const max = maxLivePrice !== 0 ? maxLivePrice : maxPrice

      result.displayPrice = min === max ? displayPriceAdapter([min * calcAmount]) : displayPriceAdapter([min * calcAmount, max * calcAmount])
    } else {
      result.displayPrice =
        minPrice === maxPrice ? displayPriceAdapter([minPrice * calcAmount]) : displayPriceAdapter([minPrice * calcAmount, maxPrice * calcAmount])
    }
    result.displayLabel = isLive
      ? minLivePrice === 0 && maxLivePrice === 0
        ? `Common_Discount_Price`
        : `Common_Live_Price`
      : `Common_Discount_Price`
  }
  return result
}

export default getDisplayPrice
