import React from 'react'
import classnames from 'classnames/bind'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useWindowWidth } from '@react-hook/window-size'
import useMedia from '@/hooks/useMedia'
import { isEmpty } from 'lodash'

// Libs
import { useBannerList } from './hooks/useBannerList'
import { handleGtmSelectPromotion, handleGtmViewPromotion } from '@/methods/handleGtmEvent'

// Components
import Image from '@/components/common/Image'

// Constants
import { MAX_MOBILE_WIDTH } from '@/constants/layout'
import { BANNER_IMAGE_SIZE } from '@/constants/banner'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

const BANNER_IMAGE_DESKTOP_HEIGHT = 320
const BANNER_IMAGE_MOBILE_HEIGHT = 120

interface IBannerProps {
  className?: any
  location: string
  autoplay?: boolean
}

const Banner = (props: IBannerProps) => {
  const { className, location, autoplay = true } = props
  const { isDesktop, isMobile } = useMedia()
  const viewportWidth = useWindowWidth()
  const { bannerList } = useBannerList({
    location: viewportWidth >= MAX_MOBILE_WIDTH ? BANNER_IMAGE_SIZE[`${location}_DESKTOP`] : BANNER_IMAGE_SIZE[`${location}_MOBILE`],
  })

  return (
    <div className={cx({ [className]: !isEmpty(className) && bannerList.isSuccess && bannerList?.data?.length > 0 })}>
      <div className={cx('banner')}>
        {!bannerList.isSuccess && (
          <div className={cx('banner__carousell')}>
            <Skeleton
              style={{ display: 'inline-block' }}
              borderRadius={10}
              height={!isMobile ? BANNER_IMAGE_DESKTOP_HEIGHT : BANNER_IMAGE_MOBILE_HEIGHT}
            />
          </div>
        )}
        {bannerList?.isSuccess && bannerList?.data?.length > 0 && (
          <Swiper
            className={cx('banner__carousell')}
            slidesPerView={1}
            spaceBetween={10}
            centeredSlides={true}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            allowTouchMove={true}
            speed={500}
            autoplay={
              autoplay
                ? {
                    delay: 5000,
                    disableOnInteraction: false,
                  }
                : false
            }
            onSlideChange={(swiper) => {
              const bannerDataList = bannerList?.data
              const activeBannerData = bannerDataList[swiper.activeIndex]
              handleGtmViewPromotion({
                creativeName: activeBannerData.creativeName,
                creativeSlot: activeBannerData.location,
              })
            }}
            onInit={() => {
              if (bannerList?.data?.length > 0) {
                const bannerDataList = bannerList?.data
                const activeBannerData = bannerDataList[0]
                handleGtmViewPromotion({
                  creativeName: activeBannerData.creativeName,
                  creativeSlot: activeBannerData.location,
                })
              }
            }}
          >
            {bannerList.data.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <a
                  href={!isEmpty(item.imgLink) ? item.imgLink : null}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    const bannerData = bannerList?.data[index]
                    handleGtmSelectPromotion({
                      creativeName: bannerData.creativeName,
                      creativeSlot: bannerData.location,
                    })
                  }}
                >
                  <div className={cx('banner__image-wrapper')}>
                    <Image src={item.imgUrl} width={isDesktop ? 2760 : 680} height={160} alt='Banner' style={{ objectFit: 'cover' }} />
                  </div>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export default Banner
