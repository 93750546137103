export const BANNER_IMAGE_SIZE: any = {
  HOME_DESKTOP: 'HOME_BANNER_V2_PC',
  HOME_MOBILE: 'HOME_BANNER_V2_MOB',
  EVENT_DESKTOP: 'SHOP_EVENT_PC',
  EVENT_MOBILE: 'SHOP_EVENT_MOB',
}

export const BANNER_LOCATION = {
  HOME: 'HOME',
  EVENT: 'EVENT',
}
